/* eslint-disable @typescript-eslint/no-var-requires */
export const environment: any = {
  production: true,
  env: 'production',
  api: {
    host: 'https://sync.erpclass.com.br',
    prefix: '/api/v1',
  },
  socket: {
    url: 'https://sync.erpclass.com.br',
  },
  build:
    'Version: ' +
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    require('../../package.json').version +
    ' - Build Date: ' +
    new Date().toLocaleDateString('pt-BR') +
    ' ' +
    new Date().toLocaleTimeString('pt-BR'),
};
